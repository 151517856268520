<template>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" class="relative z-50" @close="close">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex items-center justify-center min-h-full p-4 text-center">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
                <div class="flex justify-between items-center">
                  <h3 class="text-lg font-medium text-gray-900">Tanya Detail</h3>
                  <button @click="close" class="text-primary hover:text-gray-500">
                    <span class="sr-only">Close</span>
                    <i class="fas fa-times"></i>
                  </button>
                </div>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Silahkan isi nomor WhatsApp anda untuk memulai percakapan dengan sales konsultan kami.
                  </p>
                </div>
                <div class="mt-4">
                  <label for="email" class="block text-start text-sm font-medium text-gray-700">Email</label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Masukkan Email Anda"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    v-model="email"
                  >
                </div>
                <div class="mt-4">
                  <label for="name" class="block text-start text-sm font-medium text-gray-700">Nama</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Masukkan nama Anda"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    v-model="name"
                  >
                </div>
                <div class="mt-4">
                  <label for="phone" class="block text-start text-sm font-medium text-gray-700">Nomor Telepon</label>
                  <input
                    type="text"
                    id="phone"
                    placeholder="Masukkan nomor telepon Anda"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    v-model="phone"
                  >
                </div>
                <div class="mt-6">
                  <button
                    @click="sendMessage"
                    class="w-full px-4 py-2 text-white bg-primary rounded-md"
                  >
                    <i class="fab fa-whatsapp mr-2"></i> Kirim
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>

  <script lang="ts" setup>
  import { ref } from 'vue';
  import { Dialog, DialogOverlay, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';
  import { createCrmLeadPublic } from '~/server/api/apiService';
  import Swal from 'sweetalert2';
  import {showMessage} from '~/utils/index'

  const props = defineProps({
    isOpen: {
      type: Boolean,
      required: true
    }
  });

  const emit = defineEmits(['close']);

  const name = ref('');
  const phone = ref('');
  const email = ref('');

  const close = () => {
    emit('close');
  };

  const sendMessage = async () => {
  // Function to show SweetAlert toast


  if (!name.value) {
    showMessage('Nama wajib diisi', 'danger');
    return;
  }

  if (!phone.value) {
    showMessage('Nomor Telepon wajib diisi', 'danger');
    return;
  }
  if (!email.value) {
    showMessage('Email wajib diisi', 'danger');
    return;
  }

  try {
    // Define the parameters for the API call
    const params = {
      company_id: 1, // Replace with actual company ID
      customer_id: 0, // Replace with actual customer ID
      email_from: email.value, // Replace with actual email
      lead_id: 0, // Replace with actual lead ID
      name: name.value,
      offset: 0,
      limit: 0,
      phone: phone.value,
    };

    // Make the API call to create a CRM lead
    const response = await createCrmLeadPublic(params);
    console.log('CRM lead created:', response);

    // Show success message
    showMessage('CRM lead created.', 'success');

    close();
  } catch (error) {
    console.error('Error creating CRM lead:', error);
    showMessage('Failed to create CRM lead. Please try again.', 'danger');
  }
}

  </script>

  <style scoped>
  /* Custom styles here if needed */
  </style>
